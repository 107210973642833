@import url(https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300);
.App {
    text-align: center;
    height: 100vh;
}

.version {
    opacity: 0.5;
    position: fixed;
    bottom: 2%;
    right: 2%;
}

/* @import url("https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300"); */

nav {
    /* padding: 0px 30px; */
    /* padding-top: 10px; */

    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* min-height: 7vh; */
    background: rgb(115, 147, 179);
    color: white;
    /* position: fixed; */
    width: 100%;
    max-height: 48px;
    height: 48px;
}

nav h2 {
    /* margin-top: 25px;
    margin-left: 25px; */
}

.nav-links {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    font-family: "Montserrat", sans-serif;
    /* font-weight: 300; */
    font-size: small;
    text-transform: uppercase;
}

li {
    /* white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px; */
}
li:hover {
    color: lightgray;
}

.landing-page-container {
  /* For some reason this container needs a border to maintain the size */
  /* border-style: solid; */
  /* border-color: rgba(240, 248, 255, 0); */
  /* background: rgb(115, 147, 179); */
  height: 100%;
  width: 100%;
  padding: 15%;
  padding-top: 10%;
}

.hero-container {
  max-width: 500px;
  /* border-radius: 5px; */
  /* border-right-style: solid;
  border-bottom-style: solid;
  border-color: lightgrey;
  border-width: 1px; */
  /* padding: 20px; */
}

.headline {
  /* height: 100%; */
  width: 100%;
  /* color: white; */
  text-align: left;
  font-size: xx-large;
  /* padding: 20%; */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  margin-bottom: 15px;
  line-height: 35px;
}

.description {
  text-align: left;
  font-size: medium;
  color: grey;
}

.call-to-action {
  margin-top: 20px;
  width: 200px;
  height: 40px;
  text-align: center;
  background-color: rgb(115, 147, 179);
  color: white;
  border-radius: 5px;
  line-height: 40px;
}

.calendar-icon {
  width: 50px;
  height: auto;
}

.search-bar-container {
    max-width: 575px;
    min-width: 250px;
    margin: auto;
    display: flex;
    /* justify-content: space-between; */
}

.search-bar-text-field {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    text-align: left;
}

.search-bar-magnifying-glass {
    width: 20px;
    height: 20px;
    font-size: x-large;
    position: relative;
    top: 10px;
    left: 15px;
}

.search-bar-create-button {
    margin: auto;
    /* background-color: aquamarine; */
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
}

.search-bar-input {
    background-color: transparent;
    /* background-color: red; */
    color: black;
    outline: none;
    border: none;
    font-size: 25px;
    position: relative;
    top: 10px;
    left: 25px;
    width: 90%;
    margin-bottom: 25px;
}

.search-bar-input::placeholder {
    color: rgb(155, 155, 155);
}

.mealplan-page-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    padding: 50px;
    padding-top: 25px;
}

.mealplan-search-page-controls {
    width: 300px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
}

.page-prev-button {
    font-size: x-large;
}

.page-prev-button:hover {
    cursor: pointer;
}

.page-next-button {
    font-size: x-large;
}

.page-next-button:hover {
    cursor: pointer;
}

.gallery-card-container {
    width: 220px;
    height: 333px;
    margin: 10px 10px;
    /* background-color: black; */
    /* border-style: solid; */
    /* margin-bottom: 20px; */
}

.gallery-card-image-container {
    width: 100%;
    height: 225px;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: lightgray;
    background-image: url(/static/media/1.38ee149d.jpeg);
    background-size: 220px 225px;
}

.gallery-card-name {
    font-size: larger;
    text-align: left;
    margin: 10px 0px;
    /* background-color: blue; */
}

.gallery-new-card-name {
    font-size: larger;
    text-align: center;
    margin: 20px 0px;
    font-weight: bolder;
}

.gallery-card-description {
    width: 220px;
    font-size: small;
    text-align: left;
    margin: 5px 0px;
    color: grey;
}

.gallery-container {
    max-width: 1200px;
    height: 100%;
    margin: auto;
    /* background-color: aquamarine; */
    display: block;
}

.gallery-cards-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    /* margin-top: 80px; */
    /* background-color: aliceblue; */
    justify-content: center;
}

.gallery-title {
    text-align: center;
    /* background-color: aliceblue; */
    margin: 15px;
    margin-top: 60px;
    font-size: x-large;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
}

.mealplan-detail-container {
    margin: 5% 10%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border-style: solid; */
    /* border-color: greenyellow; */
}

.mealplan-detail-header {
    /* border-style: solid;
    border-color: purple; */
}

.mealplan-detail-header h1 {
    font-size: xx-large;
    font-weight: lighter;
}

.mealplan-detail-content-container {
    margin: 5vh 0vh;
    /* background-color: blueviolet; */
    height: 1000px;
    width: 100%;
}

.mealplan-detail-description {
    /* border-style: solid;
    border-color: red; */
    margin: 0vh 1vh;
    text-align: left;
    margin-bottom: 5vh;
    flex-grow: 3;
}

.mealplan-detail-description h4 {
    font-weight: bolder;
    font-size: large;
    /* background-color: aqua; */
}

.mealplan-detail-description p {
    /* background-color: blueviolet; */
}

.mealplan-detail-calendar-container {
    /* background-color: green; */
    display: flex;
    height: 100%;
    width: 100%;

    flex-wrap: wrap;
    justify-content: space-between;
}

.mealplan-detail-calendar-column-1 {
    flex-grow: 1;
    display: inline-block;
    /* background-color: pink; */
    /* border-style: solid; */
    min-width: 160px;
    max-width: 160px;
    /* flex-direction: column; */
}

.mealplan-detail-calendar-column-header {
    /* display: inline-block; */
    margin: 1vh;
    /* padding: 2vh 1vh; */
    /* font-weight: bolder; */
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /* font-size: large; */
    font-size: 11px;
    font-weight: 500;
    background-color: rgb(115, 147, 179);
    height: 25px;
    padding: 4px;
    color: white;
}

.meal-card-container {
    display: inline-block;
    width: 100%;
}

.mealplan-detail-peripherals {
    margin: 1vh;
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
}

.mealplan-detail-peripheral-buttons {
    flex-grow: 1;
    /* background-color: aqua; */
    display: flex;
    justify-content: flex-end;
}

.mealplan-detail-calendar-column-2 {
    /* flex-direction: column; */
    flex-wrap: nowrap;
    /* background-color: blue; */
    width: 100%;
    height: 100%;
}

.mealplan-detail-create-plannedmeal-button {
    display: inline-block;
    /* background-color: blue; */
    /* border-style: solid; */
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: xx-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 14px 0;
    text-align: center;
}

.mealplan-detail-set-favorite-button {
    display: inline-block;
    /* background-color: blue; */
    /* border-style: solid; */
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: xx-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 13px 0;
    text-align: center;
}

.mealplan-detail-set-favorite-button:hover {
    color: white;
    background-color: rgb(115, 147, 179);
}

.mealplan-detail-create-plannedmeal-button:hover {
    color: white;
    background-color: rgb(115, 147, 179);
}

.mealplan-detail-shopping-list-button {
    display: inline-block;
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: x-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 19px 0px;
    padding-right: 2px;
    text-align: center;
}

.mealplan-detail-shopping-list-button:hover {
    background-color: rgb(115, 147, 179);
    color: white;
}

.mealplan-detail-delete-mealplan-button {
    display: inline-block;
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: x-large;
    color: rgb(218, 40, 40);
    border-color: rgb(218, 40, 40);
    padding: 19px 15px;
    text-align: center;
}

.mealplan-detail-delete-mealplan-button:hover {
    color: white;
    background-color: rgb(218, 40, 40);
}

.edit-button {
    border: none;
    background-color: white;
    outline: none;
}

.edit-button:active {
    outline: none;
    border: none;
}

.edit-label-input {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 700px;
    max-width: 700px;
    min-width: 700px;
    min-height: 40px;
    max-height: 200px;
}

.mealplan-detail-meal-card {
    /* padding: 5px 0vh; */
    /* border-style: solid; */
    /* border-color: lightgray;
    border-radius: 5px;
    border-width: 1px; */
    /* margin: 1vh 0vh; */
    color: black;
    font-size: 12px;
    display: inline-block;
    width: 100%;
}

.mealplan-detail-meal-time {
    /* padding: 0vh 1vh; */
    margin: auto;
    padding-left: 8px;
    display: inline-block;
    font-size: small;
    text-align: left;
    width: 100%;
    color: grey;
    font-style: italic;
}

.mealplan-detail-meal-name {
    text-align: left;
    /* padding: 1vh 1vh; */
    padding-left: 8px;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
}

.mealplan-detail-meal-preference-name {
    text-align: left;
    /* padding: 1vh 1vh; */
    padding-left: 8px;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
    color: lightgrey;
}

.mealplan-detail-meal-name-missing {
    margin-top: 1vh;
    padding: 1vh 1vh;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
    min-height: 60px;
}

.mealplan-detail-meal-name:hover {
    color: blue;
    text-decoration: underline;
}

.meal-card-container {
    display: flex;
    width: 100%;
}

.plannedmeal-card-image {
    width: 50px;
    height: 50px;
    /* margin: 5px;
    margin-top: 3px; */
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: lightgray;
    /* background-image: url("https://picsum.photos/220/333"); */
}

.plannedmeal-card-top-row {
    display: flex;
    justify-content: space-between;
}

.plannedmeal-card-delete:hover {
    color: red;
}

.plannedmeal-card-edit {
    margin-right: 5px;
}

.plannedmeal-card-edit:hover {
    color: grey;
}

.meal-card-details {
    width: 100%;
}

.meal-page-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    padding: 50px;
    padding-top: 25px;
}

.meal-search-page-controls {
    width: 300px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
}

.page-prev-button {
    font-size: x-large;
}

.page-prev-button:hover {
    cursor: pointer;
}

.page-next-button {
    font-size: x-large;
}

.page-next-button:hover {
    cursor: pointer;
}

.search-results-container {
    /* background-color: aquamarine; */
    width: 600px;
    margin: auto;
    margin-top: 40px;
}

.result-container {
    background-color: whitesmoke;
    margin: 20px;
    padding: 15px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.006), 0 10px 80px rgba(0, 0, 0, 0.12);
}

.result-container:hover {
    transition-duration: 250ms;
    background-color: rgba(128, 128, 128, 0.548);
    color: white;
}

.ingredient-page-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    padding: 50px;
    padding-top: 25px;
}

.ingredient-page-header {
    margin: 30px;
    font-size: x-large;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
}

.ingredient-search-page-controls {
    width: 300px;
    margin: 0px auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.page-prev-button {
    font-size: x-large;
}

.page-prev-button:hover {
    cursor: pointer;
}

.page-next-button {
    font-size: x-large;
}

.page-next-button:hover {
    cursor: pointer;
}

.dashboard-container {
    /* background-color: pink; */
    height: 1000px;
    /* overflow: auto; */
}

.dashboard-header-container {
    /* background-color: plum; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.meal-timeline-container {
    display: flex;
    /* flex-direction: row; */
    flex-flow: row wrap;
    /* background-color: gold; */
    /* overflow: auto; */
    /* margin: 10vh;
    margin-top: 0vh; */
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}

.meal-timeline-content-left {
    /* background-color: lightgoldenrodyellow; */
    margin: 5vh;
    height: 300px;
    max-width: 500px;
    min-width: 200px;
    flex-grow: 1;
}

.meal-timeline-content-right {
    /* background-color: lightblue; */
    margin: 5vh;
    margin-left: 10vh;
    margin-right: 10vh;
    height: 300px;
    /* width: 600px; */
    max-width: 800px;
    min-width: 300px;
    flex-grow: 2;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}

.meal-timeline-content-right h3 {
    margin-top: 3vh;
}



.timeline-row-container {
    /* margin: 3vh; */
    /* background-color: cadetblue; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-left: 2vh;
    padding-right: 2vh;
}

.timeline-row-container:hover {
    border-radius: 50px;
    background-color: rgb(245, 244, 244);
    /* border-style: solid; */
}

.timeline-row-time {
    /* flex-grow: 1; */
    /* background-color: blueviolet; */
    text-align: right;
    width: 100px;
    margin-right: 10vh;
    font-size: large;
}

.timeline-row-node {
    background-color: lightgray;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    /* margin: 5px; */
    width: 40px;
    height: 40px;
    position: relative;
    top: -10px;
    left: -20px;
    border-radius: 25px;
}

.timeline-row-meal-name {
    /* flex-grow: 1; */
    /* background-color: orange; */
    text-align: left;
    width: 200px;
    margin-left: 10vh;
    font-size: large;
}

.timeline-row-bar {
    width: 1px;
    height: 80px;
    position: relative;
    top: 30px;
    border-width: 0.01em;
    border-style: solid;
    border-color: black;
}

.hide-bar {
    border-style: none;
}

.is-past-font {
    color: lightgray;
}

.is-past-background {
    background-color: rgb(115, 147, 179);
}

.product-page-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    padding: 50px;
    padding-top: 25px;
}

.product-search-page-controls {
    width: 300px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
}

.page-prev-button {
    font-size: x-large;
}

.page-prev-button:hover {
    cursor: pointer;
}

.page-next-button {
    font-size: x-large;
}

.page-next-button:hover {
    cursor: pointer;
}

.notification-modal-container {
  /* Location */
  position: relative;
  margin-bottom: 5px;

  /* Dimensions */
  width: 200px;
  height: 50px;

  /* Formatting: Center vertically */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Add style */
  background-color: lightgrey;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s ease-in-out;
}

.noti-content-container {
  padding-left: 20px;
  padding-right: 20px;

  color: black;
  font-size: x-small;
  line-height: normal;
  text-align: left;
}

.noti-modal-column {
  /* Format divs */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.noti-modal-close-button-container {
  position: absolute;
  top: 5px;
  right: 5px;
  /* transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out; */
}

.hide {
  transform: translateX(150%);
}

.progress-bar-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.notification-center-container {
  /* Location */
  position: fixed; /* or 'absolute' based on your requirements */
  z-index: 1000; /* Set a high z-index to ensure it appears on top */
  top: 53px;
  right: 16px;
}

